.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Logo {
  height: 16vh;
  margin-top: 3vh;
}

.Buttons {
  margin-top: 10vh;
}

.Instructions {
  margin-top: 5vh;
}

.Button {
  margin: 0 2vw;
}

.Footer {
  margin: auto 5vw 1vh;
  font-size: 1.2vh;
}

.FacebookLogo {
  height: 6vh;
  margin-bottom: 3vh;
}
