.Centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CenteredScreen {
  composes: Centered;
  height: 100vh;
}

.bottomSpace {
  margin-bottom: 2vh;
}

.error {
  color: rgb(244, 67, 54);
  font-size: 2vh;
}