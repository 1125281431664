body {
  margin: 0;
  padding: 0 10vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222;
  color: #fff;
  cursor: default;
  font-size: 2.3vh;
  text-align: center;
}

a {
  color: #fff;
}

button {
  border: 0px solid transparent;
  padding: 1vh 1.8vh;
  font-size: 2.5vh;
  border-radius: 0.6vh;
  cursor: pointer;
  color: #fff;
  background-color: #199b94;
  transition: color .25s ease-in-out,background-color .25s ease-in-out,border-color .25s ease-in-out,box-shadow .25s ease-in-out;
}

button:hover:enabled {
  background-color: #56DBC8;
}

button:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

input, select {
  width: calc(100% - 4vw - 2px);
  padding: 1.3vh 2vw;
  font-size: 2vh;
  background-color: #fff;
  background-image: none;
  border: 1px solid;
  border-color: #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-property: border-color, box-shadow;
  transition-duration: 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: initial, initial;
}

input:focus, select:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
